export const BULLET = '•'
export const TIMES = '×'
export const LEFT_POINTING = '‹'
export const RIGHT_POINTING = '›'
export const TOP_POINTING = '︿'
export const ELLIPSIS = '…'
export const STAR = '★'
export const HEART = '♥'
export const HEART_EMPTY = '♡'
export const VARIATION_SELECTOR = '\uFE0E'
export const NBSP = ' '
export const DASH = '—'
