
export const GENERATED_DATA_HASH = "afe4a404"

/** @type {import('#lib/parsing/combine').CharacterShortInfo[]} */
export const charactersShortList = [
	{
		"code": "amber",
		"elementCode": "pyro",
		"weaponTypeCode": "bow",
		"rarity": 4
	},
	{
		"code": "barbara",
		"elementCode": "hydro",
		"weaponTypeCode": "catalyst",
		"rarity": 4
	},
	{
		"code": "beidou",
		"elementCode": "electro",
		"weaponTypeCode": "claymore",
		"rarity": 4
	},
	{
		"code": "bennett",
		"elementCode": "pyro",
		"weaponTypeCode": "sword",
		"rarity": 4
	},
	{
		"code": "chongyun",
		"elementCode": "cryo",
		"weaponTypeCode": "claymore",
		"rarity": 4
	},
	{
		"code": "diluc",
		"elementCode": "pyro",
		"weaponTypeCode": "claymore",
		"rarity": 5
	},
	{
		"code": "fischl",
		"elementCode": "electro",
		"weaponTypeCode": "bow",
		"rarity": 4
	},
	{
		"code": "jean",
		"elementCode": "anemo",
		"weaponTypeCode": "sword",
		"rarity": 5
	},
	{
		"code": "kaeya",
		"elementCode": "cryo",
		"weaponTypeCode": "sword",
		"rarity": 4
	},
	{
		"code": "keqing",
		"elementCode": "electro",
		"weaponTypeCode": "sword",
		"rarity": 5
	},
	{
		"code": "klee",
		"elementCode": "pyro",
		"weaponTypeCode": "catalyst",
		"rarity": 5
	},
	{
		"code": "lisa",
		"elementCode": "electro",
		"weaponTypeCode": "catalyst",
		"rarity": 4
	},
	{
		"code": "mona",
		"elementCode": "hydro",
		"weaponTypeCode": "catalyst",
		"rarity": 5
	},
	{
		"code": "ningguang",
		"elementCode": "geo",
		"weaponTypeCode": "catalyst",
		"rarity": 4
	},
	{
		"code": "noelle",
		"elementCode": "geo",
		"weaponTypeCode": "claymore",
		"rarity": 4
	},
	{
		"code": "qiqi",
		"elementCode": "cryo",
		"weaponTypeCode": "sword",
		"rarity": 5
	},
	{
		"code": "razor",
		"elementCode": "electro",
		"weaponTypeCode": "claymore",
		"rarity": 4
	},
	{
		"code": "sucrose",
		"elementCode": "anemo",
		"weaponTypeCode": "catalyst",
		"rarity": 4
	},
	{
		"code": "traveler-anemo",
		"elementCode": "anemo",
		"weaponTypeCode": "sword",
		"rarity": 5
	},
	{
		"code": "traveler-dendro",
		"elementCode": "dendro",
		"weaponTypeCode": "sword",
		"rarity": 5
	},
	{
		"code": "traveler-electro",
		"elementCode": "electro",
		"weaponTypeCode": "sword",
		"rarity": 5
	},
	{
		"code": "traveler-geo",
		"elementCode": "geo",
		"weaponTypeCode": "sword",
		"rarity": 5
	},
	{
		"code": "venti",
		"elementCode": "anemo",
		"weaponTypeCode": "bow",
		"rarity": 5
	},
	{
		"code": "xiangling",
		"elementCode": "pyro",
		"weaponTypeCode": "polearm",
		"rarity": 4
	},
	{
		"code": "xingqiu",
		"elementCode": "hydro",
		"weaponTypeCode": "sword",
		"rarity": 4
	},
	{
		"code": "diona",
		"elementCode": "cryo",
		"weaponTypeCode": "bow",
		"rarity": 4
	},
	{
		"code": "tartaglia",
		"elementCode": "hydro",
		"weaponTypeCode": "bow",
		"rarity": 5
	},
	{
		"code": "xinyan",
		"elementCode": "pyro",
		"weaponTypeCode": "claymore",
		"rarity": 4
	},
	{
		"code": "zhongli",
		"elementCode": "geo",
		"weaponTypeCode": "polearm",
		"rarity": 5
	},
	{
		"code": "albedo",
		"elementCode": "geo",
		"weaponTypeCode": "sword",
		"rarity": 5
	},
	{
		"code": "ganyu",
		"elementCode": "cryo",
		"weaponTypeCode": "bow",
		"rarity": 5
	},
	{
		"code": "hu-tao",
		"elementCode": "pyro",
		"weaponTypeCode": "polearm",
		"rarity": 5
	},
	{
		"code": "xiao",
		"elementCode": "anemo",
		"weaponTypeCode": "polearm",
		"rarity": 5
	},
	{
		"code": "rosaria",
		"elementCode": "cryo",
		"weaponTypeCode": "polearm",
		"rarity": 4
	},
	{
		"code": "eula",
		"elementCode": "cryo",
		"weaponTypeCode": "claymore",
		"rarity": 5
	},
	{
		"code": "yanfei",
		"elementCode": "pyro",
		"weaponTypeCode": "catalyst",
		"rarity": 4
	},
	{
		"code": "kazuha",
		"elementCode": "anemo",
		"weaponTypeCode": "sword",
		"rarity": 5
	},
	{
		"code": "ayaka",
		"elementCode": "cryo",
		"weaponTypeCode": "sword",
		"rarity": 5
	},
	{
		"code": "sayu",
		"elementCode": "anemo",
		"weaponTypeCode": "claymore",
		"rarity": 4
	},
	{
		"code": "yoimiya",
		"elementCode": "pyro",
		"weaponTypeCode": "bow",
		"rarity": 5
	},
	{
		"code": "aloy",
		"elementCode": "cryo",
		"weaponTypeCode": "bow",
		"rarity": 5
	},
	{
		"code": "kokomi",
		"elementCode": "hydro",
		"weaponTypeCode": "catalyst",
		"rarity": 5
	},
	{
		"code": "kujou-sara",
		"elementCode": "electro",
		"weaponTypeCode": "bow",
		"rarity": 4
	},
	{
		"code": "raiden-shogun",
		"elementCode": "electro",
		"weaponTypeCode": "polearm",
		"rarity": 5
	},
	{
		"code": "thoma",
		"elementCode": "pyro",
		"weaponTypeCode": "polearm",
		"rarity": 4
	},
	{
		"code": "gorou",
		"elementCode": "geo",
		"weaponTypeCode": "bow",
		"rarity": 4
	},
	{
		"code": "itto",
		"elementCode": "geo",
		"weaponTypeCode": "claymore",
		"rarity": 5
	},
	{
		"code": "shenhe",
		"elementCode": "cryo",
		"weaponTypeCode": "polearm",
		"rarity": 5
	},
	{
		"code": "yun-jin",
		"elementCode": "geo",
		"weaponTypeCode": "polearm",
		"rarity": 4
	},
	{
		"code": "yae-miko",
		"elementCode": "electro",
		"weaponTypeCode": "catalyst",
		"rarity": 5
	},
	{
		"code": "ayato",
		"elementCode": "hydro",
		"weaponTypeCode": "sword",
		"rarity": 5
	},
	{
		"code": "kuki-shinobu",
		"elementCode": "electro",
		"weaponTypeCode": "sword",
		"rarity": 4
	},
	{
		"code": "yelan",
		"elementCode": "hydro",
		"weaponTypeCode": "bow",
		"rarity": 5
	},
	{
		"code": "heizou",
		"elementCode": "anemo",
		"weaponTypeCode": "catalyst",
		"rarity": 4
	},
	{
		"code": "collei",
		"elementCode": "dendro",
		"weaponTypeCode": "bow",
		"rarity": 4
	},
	{
		"code": "dori",
		"elementCode": "electro",
		"weaponTypeCode": "claymore",
		"rarity": 4
	},
	{
		"code": "tighnari",
		"elementCode": "dendro",
		"weaponTypeCode": "bow",
		"rarity": 5
	},
	{
		"code": "candace",
		"elementCode": "hydro",
		"weaponTypeCode": "polearm",
		"rarity": 4
	},
	{
		"code": "cyno",
		"elementCode": "electro",
		"weaponTypeCode": "polearm",
		"rarity": 5
	},
	{
		"code": "nilou",
		"elementCode": "hydro",
		"weaponTypeCode": "sword",
		"rarity": 5
	},
	{
		"code": "layla",
		"elementCode": "cryo",
		"weaponTypeCode": "sword",
		"rarity": 4
	},
	{
		"code": "nahida",
		"elementCode": "dendro",
		"weaponTypeCode": "catalyst",
		"rarity": 5
	},
	{
		"code": "faruzan",
		"elementCode": "anemo",
		"weaponTypeCode": "bow",
		"rarity": 4
	},
	{
		"code": "wanderer",
		"elementCode": "anemo",
		"weaponTypeCode": "catalyst",
		"rarity": 5
	},
	{
		"code": "alhaitham",
		"elementCode": "dendro",
		"weaponTypeCode": "sword",
		"rarity": 5
	},
	{
		"code": "yaoyao",
		"elementCode": "dendro",
		"weaponTypeCode": "polearm",
		"rarity": 4
	}
]

/** @type {import('#lib/parsing').ArtifcatSetGroupsCodes} */
export const ART_GROUP_CODES = {
	"18%-atk": [
		"echoes-of-an-offering",
		"gladiators-finale",
		"shimenawas-reminiscence",
		"vermillion-hereafter",
		"brave-heart",
		"resolution-of-sojourner"
	],
	"25%-ph-atk": [
		"bloodstained-chivalry",
		"pale-flame"
	],
	"15%-anemo": [
		"desert-pavilion-chronicle",
		"viridescent-venerer"
	],
	"20%-er": [
		"emblem-of-severed-fate",
		"scholar",
		"the-exile"
	],
	"80-em": [
		"flower-of-paradise-lost",
		"gilded-dreams",
		"wanderers-troupe",
		"instructor"
	],
	"15%-heal": [
		"maiden-beloved",
		"ocean-hued-clam"
	]
}
